<template>
  <div class="row row_margin">
    <div class="col-lg-12 px-0">
      <b-card>
        <h6 class="font-weight mb-0">
          {{ items.length }} Result found for Sourced
        </h6>
      </b-card>
      <b-card no-body class="p-1">
        <b-row>
          <b-col
            lg="5"
            md="5"
            sm="4"
            class="pb-1 my-auto d-flex justify-content-start align-items-center"
          >
            <b-form-group class="d-flex my-auto">
              <label class="d-inline text-sm-left">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
            <div>
              <span class="executive">Executive</span>
              <b-form-checkbox
                v-model="isExpert"
                checked="false"
                name="check-button"
                switch
                inline
              >
                Expert
              </b-form-checkbox>
            </div>
          </b-col>

          <b-col
            lg="7"
            md="7"
            class="pb-1 d-flex justify-content-end align-items-center"
          >
            <b-form-group
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              responsive
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(show_details)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  class="vs-checkbox-con"
                  @change="row.toggleDetails"
                >
                  <span class="vs-label">{{
                    row.detailsShowing ? "Hide" : "Show"
                  }}</span>
                </b-form-checkbox>
              </template>
              <template #row-details="row">
                <b-card class="mb-0">
                  <div class="card__box">
                    <div class="mb-0 card__left">
                      <b-col
                        md="12"
                        v-if="
                          row.item.candidate &&
                          row.item.candidate.experiences &&
                          row.item.candidate.experiences.length !== 0
                        "
                      >
                        <div class="row pb-2">
                          <div class="y-center col-lg-2 bor-1">
                            <h6>Experience</h6>
                          </div>
                          <div class="col-lg-10">
                            <div>
                              <app-timeline
                                v-for="(experience, i) in row.item.candidate
                                  .experiences"
                                :key="i"
                              >
                                <app-timeline-item variant="primary">
                                  <div
                                    class="d-flex flex-sm-row flex-column mb-sm-0 mb-1"
                                  >
                                    <h6 class="mr-1 inline">
                                      {{ experience.designation }}
                                    </h6>
                                    <span class="mr-1 font-11 inline">at</span>
                                    <h6 class="mr-1 inline">
                                      {{ experience.location }}
                                    </h6>
                                    <span class="font-11"
                                      >{{ experience.from }}
                                    </span>
                                    <span class="font-11 mx-1"> to </span>
                                    <span class="font-11">
                                      {{ experience.to }}</span
                                    >
                                  </div>
                                </app-timeline-item>
                              </app-timeline>
                            </div>
                          </div>
                        </div>
                      </b-col>

                      <b-col
                        md="12"
                        v-if="
                          row.item.candidate &&
                          row.item.candidate.educations &&
                          row.item.candidate.educations.length !== 0
                        "
                      >
                        <div class="row pb-2">
                          <div class="y-center col-lg-2 bor-1">
                            <h6>Qualification</h6>
                          </div>
                          <div class="col-lg-10">
                            <div>
                              <app-timeline
                                v-for="(qualification, i) in row.item.candidate
                                  .educations"
                                :key="i"
                              >
                                <app-timeline-item variant="primary">
                                  <div
                                    class="d-flex flex-sm-row flex-column mb-sm-0 mb-1"
                                  >
                                    <h6 class="mr-1 inline">
                                      {{ qualification.degree }}
                                    </h6>
                                    <span class="mr-1 font-11 inline">at</span>
                                    <h6 class="mr-1 inline">
                                      {{ qualification.institute_name }}
                                    </h6>
                                    <span class="font-11">
                                      {{ qualification.from }}
                                    </span>
                                    <span class="font-11 mx-1"> to </span>
                                    <span class="font-11">
                                      {{ qualification.to }}
                                    </span>
                                  </div>
                                </app-timeline-item>
                              </app-timeline>
                            </div>
                          </div>
                        </div>
                      </b-col>

                      <b-col
                        md="8"
                        class="pb-1"
                        v-if="
                          row.item.candidate && row.item.candidate.industries
                        "
                      >
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Industry</h6>
                          </div>
                          <div class="col-lg-9">
                            <span
                              v-for="(industry, i) in JSON.parse(
                                row.item.candidate.industries
                              )"
                              :key="i"
                            >
                              <span>
                                <span v-if="i !== 0">|</span>
                                {{ industry.name }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        md="8"
                        class="pb-1"
                        v-if="
                          row.item.candidate && row.item.candidate.functions
                        "
                      >
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Functions</h6>
                          </div>
                          <div class="col-lg-9">
                            <span
                              v-for="(functionName, i) in JSON.parse(
                                row.item.candidate.functions
                              )"
                              :key="i"
                            >
                              <span>
                                <span v-if="i !== 0">|</span>
                                {{ functionName.name }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        md="8"
                        class="pb-1"
                        v-if="
                          row.item.candidate && row.item.candidate.sub_functions
                        "
                      >
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Sub Function</h6>
                          </div>
                          <div class="col-lg-9">
                            <span
                              v-for="(functionName, i) in JSON.parse(
                                row.item.candidate.sub_functions
                              )"
                              :key="i"
                            >
                              <span>
                                <span v-if="i !== 0">|</span>
                                {{ functionName.name }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                    </div>
                  </div>
                </b-card>
              </template>
              <template #cell(candidate_id)="data">
                candidate_0{{ data.item.id }}
              </template>
              <template #cell(status)="data">
                <b-badge
                  variant="light-success"
                  v-if="data.item.status === 'Confirmed'"
                >
                  Confirmed
                </b-badge>
              </template>
            </b-table>
          </b-col>
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"

import axios from "@/libs/axios"
import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import { required } from "@validations"

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    AppTimeline,
    AppTimelineItem,
    BDropdown,
    BDropdownItem,
    BBadge,
  },
  directives: {
    Ripple,
  },
  // props: {
  //   assignments: {
  //     type: [Object, Array],
  //     required: true,
  //   },
  // },
  data() {
    return {
      showExperience: 3,
      required,
      showQualification: 3,
      graduationStartYear: null,
      graduationEndYear: null,
      value2: null,
      graduation: [],
      dir: "ltr",
      trueValue: true,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      isExpert: false,
      filter: null,
      filterTwo: "",
      filterOn: [],
      selectInstitute: [],
      selectedCompany: [],
      selectedCompanyType: null,
      selectedLocType: null,
      selectedIndustries: [],
      industries: [],
      selectedFunctions: [],
      functions: [],
      selectedSubFunctions: [],
      subFunctions: [],
      selectedSkills: [],
      location: "",
      searchName: "",
      skills: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        "show_details",
        { key: "candidate_id", label: "Candidate", sortable: true },
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    }
  },
  computed: {},
  watch: {
    isExpert(value) {
      if (!value) {
        this.fields = [
          "show_details",
          { key: "candidate_id", label: "Candidate", sortable: true },
        ]
      } else {
        this.fields = [
          "show_details",
          { key: "candidate_id", label: "Candidate", sortable: true },
        ]
      }
    },
  },
  mounted() {
    // Set the initial number of items
    this.getCandidates()
  },
  created() {},
  updated() {},

  methods: {
    getSelected(option) {
      return (option && option.name) || ""
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async getCandidates() {
      axios
        .get(
          `/client/candidate_assignment/${this.$route.params.id}?filter[status]=Selected`
        )
        .then((res) => {
          this.items = res.data
          this.totalRows = this.items.length

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Candidate List`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully fetched Candidate List!`,
            },
          })
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
    handleStatusChange(status, data) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to Change Candidate Status.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            let sendingData = {}
            if (status === "Working") {
              sendingData = {
                status,
                assignment_id: data.assignment_id,
                candidate_id: data.candidate_id,
                inner_status: "Work in progress",
              }
            } else {
              sendingData = {
                status,
                assignment_id: data.assignment_id,
                candidate_id: data.candidate_id,
                id: data.id,
              }
            }
            axios
              .post(`candidate_assignment/change_status`, sendingData)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Assignment Status`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Changed Assignment Status!`,
                  },
                })
                this.$emit("detailed-getAssignment")
                this.getCandidates()
              })
              .catch((error) => {
                console.log(error.response.status)
                errorResponse(error, this.$router)
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.bor-1 {
  border-right: 1px solid lightgray;
}

.in__line {
  display: flex;
  flex-direction: row;
}

.y-center {
  display: flex;
  flex-direction: column;

  justify-content: center;
}

.title__box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.card__box {
  display: flex;
  justify-content: space-between;
}

.card__left {
  flex: 1;
}
.loc {
  font-size: 11px;
  padding-right: 5px;
}

.font-11 {
  font-size: 11px;
}

.bold {
  font-family: bold;
}
.inline {
  display: inline !important;
}

.name {
  margin-bottom: 0px;
}
.timeline-item {
  padding-bottom: 5px !important;
}

// .card-body {
//   padding: 0px !important;
// }
.experience_btn {
  cursor: pointer;
}
.location {
  display: inline !important;
}
.icon {
  margin-top: 5px;
}
.executive {
  margin-right: 5px;
  font-size: 12px;
}

.row_margin {
  margin-left: 0px;
  margin-right: 0px;
}
</style>

Row select support Selection mode: multi ID AVAT
