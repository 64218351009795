<template>
  <div class="card">
    <b-col cols="12" class="px-2 pt-1">
      <div cols="12" class="d-flex justify-content-between">
        <div class="d-flex">
          <b-avatar
            :src="require('@/assets/images/avatars/11-small.png')"
          ></b-avatar>
          <h4 class="font-weight my-auto ml-1">{{ assignment.user.name }}</h4>
        </div>
        <div class="d-flex align-items-center">
          <div>
            <b-button
              variant="outline-primary"
              size="sm"
              class="my-auto"
              v-if="assignment.status === 'Work in progress'"
            >
              Work in Progress
            </b-button>
            <b-button
              variant="outline-warning"
              size="sm"
              class="my-auto"
              v-if="assignment.status === 'Payment Requested'"
            >
              Payment Requested
            </b-button>
            <b-button
              variant="outline-success"
              size="sm"
              class="my-auto"
              v-if="assignment.status === 'Payment Completed'"
            >
              Payment Completed
            </b-button>
          </div>
        </div>
      </div>
    </b-col>
    <b-col cols="12" class="m-0">
      <hr />
    </b-col>
    <b-col cols="12" class="px-2 pb-1">
      <div class="d-lg-flex d-md-flex justify-content-between">
        <div class="d-lg-flex d-md-flex">
          <div class="mr-3">
            <h6>Hourly</h6>
            <h5 class="font-weight">
              <span v-if="assignment.candidate.current_currency === 'usd'">
                $
              </span>
              <span v-if="assignment.candidate.current_currency === 'inr'">
                ₹
              </span>
              {{ assignment.candidate.charge_per_hour }}
            </h5>
          </div>
          <div class="mr-3">
            <h6>Start Date</h6>
            <h5 class="font-weight">{{ assignment.interview.start_date }}</h5>
          </div>

          <!-- <div class="mr-3">
            <h6>Pay Date</h6>
            <h5 class="font-weight">31 June 2022</h5>
          </div>
          <div class="mr-3">
            <h6>Transaction ID</h6>
            <h5 class="font-weight">Tewgvs524</h5>
          </div> -->
        </div>
        <div>
          <b-button
            variant="outline-success"
            size="sm"
            class="my-auto"
            @click="handleCompleteWork(assignment)"
            v-if="assignment.status === 'Payment Requested'"
          >
            Pay Up!
          </b-button>
        </div>
      </div>
    </b-col>
  </div>
</template>
<script>
import axios from "@/libs/axios"
import errorResponse from "@/libs/axiosError"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { BButton, BCol, BAvatar } from "bootstrap-vue"

export default {
  components: {
    BButton,
    BCol,
    BAvatar,
  },
  props: {
    assignment: {
      type: [Object, Array],
      required: true,
    },
  },
  methods: {
    async handleCompleteWork(data) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to Complete the Payment.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            axios
              .post("/assignments/candidate_complete_payment", {
                candidate_id: data.candidate_id,
                assignment_id: data.assignment_id,
              })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Payment Complete`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Completed the Payment!`,
                  },
                })
                this.$emit("refresh-working-candidate")
              })
              .catch((error) => {
                console.log(error)
                errorResponse(error, this.$router)
              })
          }
        })
    },
  },
}
</script>
<style>
.font-weight {
  font-weight: 600;
}
</style>
