<template>
  <div class="card">
    <b-col cols="12" class="px-2 pt-1">
      <div cols="12" class="d-flex justify-content-between">
        <h4 class="font-weight my-auto">{{ assignments.title }}</h4>
        <div class="d-flex align-items-center">
          <b-button
            v-if="assignments.status === 'New'"
            size="sm"
            variant="outline-primary"
          >
            New
          </b-button>
          <b-button
            v-if="assignments.status === 'Archieved'"
            size="sm"
            variant="outline-secondary"
          >
            Archived
          </b-button>
          <b-button
            v-if="assignments.status === 'Payment Due'"
            size="sm"
            variant="outline-warning"
            class="mr-1"
          >
            Payment Due
          </b-button>
          <b-button
            v-if="assignments.status === 'Complete'"
            size="sm"
            variant="outline-success"
          >
            Completed
          </b-button>
          <b-button
            v-if="assignments.status === 'Active'"
            size="sm"
            variant="outline-info"
          >
            Active
          </b-button>
        </div>
      </div>
    </b-col>
    <b-col cols="12" class="m-0">
      <hr />
    </b-col>
    <b-col cols="12" class="px-2 pb-1">
      <div class="d-lg-flex d-md-flex justify-content-between">
        <div class="d-lg-flex d-md-flex">
          <div class="mr-3" v-if="assignments.client">
            <h6>Company</h6>
            <h5 class="font-weight">{{ assignments.client.name }}</h5>
          </div>
          <div class="mr-3" v-if="assignments.required_candidate">
            <h6>Quantity</h6>
            <h5 class="font-weight">{{ assignments.required_candidate }}</h5>
          </div>
          <div class="mr-3" v-if="assignments.industry">
            <h6>Industry</h6>
            <h5 class="font-weight">{{ assignments.industry }}</h5>
          </div>
          <div class="mr-3" v-if="assignments.function">
            <h6>Function</h6>
            <h5 class="font-weight">{{ assignments.function }}</h5>
          </div>
          <div class="mr-3" v-if="assignments.required_experience">
            <h6>Experience</h6>
            <h5 class="font-weight">
              {{ assignments.required_experience }} years
            </h5>
          </div>
          <!-- <div class="mr-3">
            <h6>Hourly</h6>
            <h5 class="font-weight">$400</h5>
          </div> -->
          <div class="mr-3">
            <h6>Location</h6>
            <h5 class="font-weight">{{ assignments.country }}</h5>
          </div>
        </div>
        <div>
          <b-button @click="handleSeeJob()" variant="outline-primary" size="sm">
            See Job
          </b-button>
        </div>
      </div>
    </b-col>
  </div>
</template>
<script>
import { BButton, BCol } from "bootstrap-vue"

export default {
  components: {
    BButton,
    BCol,
  },
  data: () => ({
    selectedCompanyType: "",
    // assignments: {},
  }),
  props: {
    assignments: {
      type: [Object, Array],
      required: true,
    },
  },
  mounted() {},
  methods: {
    handleSeeJob() {
      this.$router.push({
        path: `/assignment-details/${this.$route.params.id}`,
      })
    },
  },
}
</script>
<style>
.font-weight {
  font-weight: 600;
}
</style>
