<template>
  <b-row>
    <b-col cols="12" v-for="(assignment, i) in items" :key="i">
      <WorkProgress
        :assignment="assignment"
        @refresh-working-candidate="reloadPage"
      />
    </b-col>
  </b-row>
</template>
<script>
import axios from "@/libs/axios"
import errorResponse from "@/libs/axiosError"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

import { BRow, BCol } from "bootstrap-vue"
import WorkProgress from "./WorkProgress.vue"

export default {
  components: {
    BRow,
    BCol,
    WorkProgress,
  },
  data() {
    return {
      items: null,
    }
  },
  mounted() {
    // Set the initial number of items
    this.getCandidates()
  },
  methods: {
    reloadPage() {
      this.getCandidates()
    },
    async getCandidates() {
      axios
        .get(
          `/client/candidate_assignment/${this.$route.params.id}?filter[status]=Working`
        )
        .then((res) => {
          this.items = res.data

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Candidate List`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully fetched Candidate List!`,
            },
          })
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
    handleStatusChange(status, data) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to Change Candidate Status.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            let sendingData = {}
            if (status === "Working") {
              sendingData = {
                status,
                assignment_id: data.assignment_id,
                candidate_id: data.candidate_id,
                inner_status: "Work in progress",
              }
            } else {
              sendingData = {
                status,
                assignment_id: data.assignment_id,
                candidate_id: data.candidate_id,
                id: data.id,
              }
            }
            axios
              .post(`candidate_assignment/change_status`, sendingData)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Assignment Status`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Changed Assignment Status!`,
                  },
                })
                this.$emit("detailed-getAssignment")
                this.getCandidates()
              })
              .catch((error) => {
                console.log(error.response.status)
                errorResponse(error, this.$router)
              })
          }
        })
    },
  },
}
</script>
