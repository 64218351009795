<template>
  <div class="row row_margin">
    <div class="col-lg-12 px-0">
      <b-card>
        <h6 class="font-weight mb-0">
          {{ items.length }} Result found for Sourced
        </h6>
      </b-card>
      <b-card no-body class="p-1">
        <b-row>
          <b-col
            lg="5"
            md="5"
            sm="4"
            class="pb-1 my-auto d-flex justify-content-start align-items-center"
          >
            <b-form-group class="d-flex my-auto">
              <label class="d-inline text-sm-left">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
            <div>
              <span class="executive">Executive</span>
              <b-form-checkbox
                v-model="isExpert"
                checked="false"
                name="check-button"
                switch
                inline
              >
                Expert
              </b-form-checkbox>
            </div>
          </b-col>

          <b-col
            lg="7"
            md="7"
            class="pb-1 d-flex justify-content-end align-items-center"
          >
            <b-form-group
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              responsive
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(show_details)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  class="vs-checkbox-con"
                  @change="row.toggleDetails"
                >
                  <span class="vs-label">{{
                    row.detailsShowing ? "Hide" : "Show"
                  }}</span>
                </b-form-checkbox>
              </template>
              <template #cell(interview)="data">
                <feather-icon
                  @click="interviewModal(data.item)"
                  icon="EyeIcon"
                  size="16"
                  class="cursor-pointer icon text-dark ml-2"
                />
              </template>
              <template #row-details="row">
                <b-card class="mb-0">
                  <div class="card__box">
                    <div class="mb-0 card__left">
                      <b-col
                        md="12"
                        v-if="
                          row.item.candidate &&
                          row.item.candidate.experiences &&
                          row.item.candidate.experiences.length !== 0
                        "
                      >
                        <div class="row pb-2">
                          <div class="y-center col-lg-2 bor-1">
                            <h6>Experience</h6>
                          </div>
                          <div class="col-lg-10">
                            <div>
                              <app-timeline
                                v-for="(experience, i) in row.item.candidate
                                  .experiences"
                                :key="i"
                              >
                                <app-timeline-item variant="primary">
                                  <div
                                    class="d-flex flex-sm-row flex-column mb-sm-0 mb-1"
                                  >
                                    <h6 class="mr-1 inline">
                                      {{ experience.designation }}
                                    </h6>
                                    <span class="mr-1 font-11 inline">at</span>
                                    <h6 class="mr-1 inline">
                                      {{ experience.location }}
                                    </h6>
                                    <span class="font-11"
                                      >{{ experience.from }}
                                    </span>
                                    <span class="font-11 mx-1"> to </span>
                                    <span class="font-11">
                                      {{ experience.to }}</span
                                    >
                                  </div>
                                </app-timeline-item>
                              </app-timeline>
                            </div>
                          </div>
                        </div>
                      </b-col>

                      <b-col
                        md="12"
                        v-if="
                          row.item.candidate &&
                          row.item.candidate.educations &&
                          row.item.candidate.educations.length !== 0
                        "
                      >
                        <div class="row pb-2">
                          <div class="y-center col-lg-2 bor-1">
                            <h6>Qualification</h6>
                          </div>
                          <div class="col-lg-10">
                            <div>
                              <app-timeline
                                v-for="(qualification, i) in row.item.candidate
                                  .educations"
                                :key="i"
                              >
                                <app-timeline-item variant="primary">
                                  <div
                                    class="d-flex flex-sm-row flex-column mb-sm-0 mb-1"
                                  >
                                    <h6 class="mr-1 inline">
                                      {{ qualification.degree }}
                                    </h6>
                                    <span class="mr-1 font-11 inline">at</span>
                                    <h6 class="mr-1 inline">
                                      {{ qualification.institute_name }}
                                    </h6>
                                    <span class="font-11">
                                      {{ qualification.from }}
                                    </span>
                                    <span class="font-11 mx-1"> to </span>
                                    <span class="font-11">
                                      {{ qualification.to }}
                                    </span>
                                  </div>
                                </app-timeline-item>
                              </app-timeline>
                            </div>
                          </div>
                        </div>
                      </b-col>

                      <b-col
                        md="8"
                        class="pb-1"
                        v-if="
                          row.item.candidate && row.item.candidate.industries
                        "
                      >
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Industry</h6>
                          </div>
                          <div class="col-lg-9">
                            <span
                              v-for="(industry, i) in JSON.parse(
                                row.item.candidate.industries
                              )"
                              :key="i"
                            >
                              <span>
                                <span v-if="i !== 0">|</span>
                                {{ industry.name }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        md="8"
                        class="pb-1"
                        v-if="
                          row.item.candidate && row.item.candidate.functions
                        "
                      >
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Functions</h6>
                          </div>
                          <div class="col-lg-9">
                            <span
                              v-for="(functionName, i) in JSON.parse(
                                row.item.candidate.functions
                              )"
                              :key="i"
                            >
                              <span>
                                <span v-if="i !== 0">|</span>
                                {{ functionName.name }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        md="8"
                        class="pb-1"
                        v-if="
                          row.item.candidate && row.item.candidate.sub_functions
                        "
                      >
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Sub Function</h6>
                          </div>
                          <div class="col-lg-9">
                            <span
                              v-for="(functionName, i) in JSON.parse(
                                row.item.candidate.sub_functions
                              )"
                              :key="i"
                            >
                              <span>
                                <span v-if="i !== 0">|</span>
                                {{ functionName.name }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                    </div>
                  </div>
                </b-card>
              </template>
              <template #cell(candidate_id)="data">
                candidate_0{{ data.item.id }}
              </template>
              <template #cell(actions)="data">
                <div
                  class="text-nowrap"
                  v-if="data.item.status === 'Reschedule Requested'"
                >
                  <!-- Dropdown -->
                  <b-dropdown variant="link" toggle-class="p-0" no-caret left>
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item>
                      <span
                        class="align-middle ml-50"
                        @click="showRescheduleModal(data.item)"
                        >Reschedule</span
                      >
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
          </b-col>
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>

    <!-- modal for interview -->
    <b-modal
      ref="interview-modal"
      hide-footer
      centered
      title="Interview"
      size="lg"
    >
      <b-row class="">
        <b-col
          lg="12"
          md="10"
          sm="10"
          class="mx-auto"
          v-if="previewAssignmentData"
        >
          <div class="px-lg-5">
            <h4>
              Interview status of
              <span
                v-if="previewAssignmentData && previewAssignmentData.user"
                class="text-primary ml-1"
              >
                {{ previewAssignmentData.user.name }}
              </span>
            </h4>
            <div
              v-if="previewAssignmentData && previewAssignmentData.interview"
            >
              <b-form-group label="Interview Title" class="my-1">
                <!-- <b-form-input
                        v-model="updateDate"
                        selected-variant="primary"
                        placeholder="Enter Interview Title"
                        class="w-sm-75"
                      /> -->
                <h6 class="font-bold">
                  {{ previewAssignmentData.interview.title }}
                </h6>
              </b-form-group>
              <b-form-group label="Date" class="mb-1">
                <!-- <b-form-datepicker
                        v-model="updateDate"
                        selected-variant="primary"
                        class="w-sm-75"
                        default-value="555"
                        @context="setFormattedDate"
                      /> -->
                <h6 class="font-bold">
                  {{ this.previewAssignmentData.interview.start_date }}
                </h6>
              </b-form-group>
              <b-row class="d-flex justify-content-between">
                <b-col lg="6" md="6" sm="12">
                  <b-form-group label="Start Time" class="mb-1">
                    <!-- <b-form-timepicker
                            v-model="updateStartTime"
                            class=""
                            locale="en"
                            @context="setFormattedStartTime"
                          /> -->
                    <h6 class="font-bold">
                      {{ this.previewAssignmentData.interview.start_time }}
                    </h6>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12">
                  <b-form-group label="End Time" class="mb-1">
                    <!-- <b-form-timepicker
                            v-model="updateEndTime"
                            class=""
                            locale="en"
                            @context="setFormattedEndTime"
                          /> -->
                    <h6 class="font-bold">
                      {{ this.previewAssignmentData.interview.end_time }}
                    </h6>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group label="Link" class="mb-1">
                <!-- <b-form-input
                        v-model="updateEndTime"
                        class="w-sm-75"
                        placeholder="Enter meeting link"
                      /> -->
                <h6 class="font-bold">
                  {{ this.previewAssignmentData.interview.link }}
                </h6>
              </b-form-group>
              <b-form-group label="Password" class="mb-1">
                <!-- <b-form-input
                        v-model="updateEndTime"
                        class="w-sm-75"
                        type="password"
                        placeholder="Enter password"
                      /> -->
                <h6 class="font-bold">
                  {{ this.previewAssignmentData.interview.password }}
                </h6>
              </b-form-group>
              <b-form-group label="Admin Note" class="mb-1">
                <!-- <b-form-input
                        v-model="updateEndTime"
                        class="w-sm-75"
                        type="password"
                        placeholder="Enter password"
                      /> -->
                <h6 class="font-bold">
                  {{ this.previewAssignmentData.interview.sender_note }}
                </h6>
              </b-form-group>
              <b-form-group label="Candidate Note" class="mb-1">
                <!-- <b-form-input
                        v-model="updateEndTime"
                        class="w-sm-75"
                        type="password"
                        placeholder="Enter password"
                      /> -->
                <h6 class="font-bold">
                  {{ this.previewAssignmentData.interview.candidate_note }}
                </h6>
              </b-form-group>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end my-2">
          <div>
            <!-- <b-button @click="interviewEditModal()" variant="primary">
              Edit
            </b-button> -->
            <b-button
              class="ml-1"
              variant="danger"
              @click="hideInterviewModal()"
            >
              Close
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <!-- modal for interview edit -->
    <b-modal
      ref="interview-edit-modal"
      centered
      title="Edit Interview"
      size="lg"
      @ok="handleInterviewEdit"
    >
      <b-form class="">
        <b-col lg="12" md="10" sm="10" class="mx-auto">
          <div class="px-lg-5">
            <h4>
              Interview status of
              <span v-if="interviewInfo" class="text-primary ml-1">
                {{ interviewInfo.full_name }}
              </span>
            </h4>

            <b-form-group label="Interview Title" class="my-1">
              <b-form-input
                v-model="interviewInfo.interviewTitle"
                selected-variant="primary"
                placeholder="Enter Interview Title"
                class="w-sm-75"
              />
            </b-form-group>
            <b-form-group label="Select Date" class="mb-1">
              <b-form-datepicker
                v-model="interviewInfo.selectDate"
                selected-variant="primary"
                class="w-sm-75"
                default-value="555"
              />
            </b-form-group>
            <b-row class="d-flex justify-content-between">
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="Start Time" class="mb-1">
                  <b-form-timepicker
                    v-model="interviewInfo.startTime"
                    class=""
                    locale="en"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="End Time" class="mb-1">
                  <b-form-timepicker
                    v-model="interviewInfo.endTime"
                    class=""
                    locale="en"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Link" class="mb-1">
              <b-form-input
                v-model="interviewInfo.interviewLink"
                class="w-sm-75"
                placeholder="Enter meeting link"
              />
            </b-form-group>
            <b-form-group label="Password" class="mb-1">
              <b-form-input
                v-model="interviewInfo.password"
                class="w-sm-75"
                type="password"
                placeholder="Enter password"
              />
            </b-form-group>
            <b-form-group label="Note" class="mb-1">
              <b-form-input
                v-model="interviewInfo.addNote"
                class="w-sm-75"
                type="text"
                placeholder="Enter Note"
              />
            </b-form-group>
            <!-- <div class="d-flex justify-content-center">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="mx-auto d-inline-block my-3"
                          variant="primary"
                          @click="handleReschedule()"
                        >
                          Update
                        </b-button>
                      </div> -->
          </div>
        </b-col>
      </b-form>
    </b-modal>
    <!-- interview reschedule  -->
    <b-modal
      ref="reschedule-interview"
      ok-title="Save"
      modal-class="modal-primary"
      centered
      hide-footer
      size="lg"
      title="Reschedule"
    >
      <b-col lg="10" md="10" sm="10" class="mx-auto">
        <div class="px-lg-2 my-2">
          <h4>
            interview details of
            <span class="text-primary" v-if="previewAssignmentData">{{
              previewAssignmentData.user.name
            }}</span>
          </h4>
          <div class="mt-1">
            <h6 class="font-bold my-0">Interview Title</h6>
            <p class="">{{ this.previewAssignmentData.interview.title }}</p>
          </div>
          <div class="mt-1">
            <h6 class="font-bold my-0">Date</h6>
            <p class="">
              {{ this.previewAssignmentData.interview.start_date }}
            </p>
            <!-- <b-form-datepicker
              v-model="updateStartTime"
              class="w-sm-75"
              locale="en"
              @context=""
            /> -->
          </div>
          <div class="my-1">
            <h6 class="font-bold my-0">Time</h6>
            <p class="">
              {{ this.previewAssignmentData.interview.start_time }} -
              {{ this.previewAssignmentData.interview.end_time }}
            </p>
            <!-- <b-form-timepicker
              v-model="updateStartTime"
              class="w-sm-75"
              locale="en"
              @context=""
            /> -->
          </div>

          <div class="my-1">
            <h6 class="font-bold my-0">Admin Note</h6>
            <p class="my-0">
              {{
                this.previewAssignmentData.interview.sender_note || "No Note"
              }}
            </p>
          </div>
          <div>
            <h6 class="font-bold my-0">Candidate Note</h6>
            <p class="my-0">
              {{
                this.previewAssignmentData.interview.candidate_note || "No Note"
              }}
            </p>
            <!-- <b-form-textarea
              v-model="addNote"
              placeholder="Write here..."
              rows="4"
            /> -->
          </div>
        </div>
      </b-col>
      <b-col>
        <b-col cols="12" class="d-flex justify-content-end my-2">
          <div>
            <b-button @click="handleAcceptInterview()" variant="primary">
              Schedule
            </b-button>
            <!-- <b-button
              @click="rescheduleInterviewEditModal()"
              class="ml-1"
              variant="primary"
            >
              Edit
            </b-button> -->
            <b-button
              class="ml-1"
              variant="danger"
              @click="hideRescheduleInterviewModal()"
            >
              Close
            </b-button>
          </div>
        </b-col>
      </b-col>
    </b-modal>
    <!-- modal for reschedule edit -->
    <b-modal
      ref="reschedule-interview-modal-edit"
      centered
      title="Reschedule Interview"
      size="lg"
      @ok="rescheduleInterview"
    >
      <b-form class="">
        <b-col lg="12" md="10" sm="10" class="mx-auto">
          <div class="px-lg-5">
            <h4>
              Interview status of
              <span v-if="rescheduleInterviewTime" class="text-primary ml-1">
                {{ rescheduleInterviewTime.full_name }}
              </span>
            </h4>

            <b-form-group label="Interview Title" class="my-1">
              <b-form-input
                v-model="rescheduleInterviewTime.interviewTitle"
                selected-variant="primary"
                placeholder="Enter Interview Title"
                class="w-sm-75"
              />
            </b-form-group>
            <b-form-group label="Select Date" class="mb-1">
              <b-form-datepicker
                v-model="rescheduleInterviewTime.selectDate"
                selected-variant="primary"
                class="w-sm-75"
                default-value="555"
              />
            </b-form-group>
            <b-row class="d-flex justify-content-between">
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="Start Time" class="mb-1">
                  <b-form-timepicker
                    v-model="rescheduleInterviewTime.startTime"
                    class=""
                    locale="en"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group label="End Time" class="mb-1">
                  <b-form-timepicker
                    v-model="rescheduleInterviewTime.endTime"
                    class=""
                    locale="en"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Link" class="mb-1">
              <b-form-input
                v-model="rescheduleInterviewTime.interviewLink"
                class="w-sm-75"
                placeholder="Enter meeting link"
              />
            </b-form-group>
            <b-form-group label="Password" class="mb-1">
              <b-form-input
                v-model="rescheduleInterviewTime.password"
                class="w-sm-75"
                type="password"
                placeholder="Enter password"
              />
            </b-form-group>
            <b-form-group label="Note" class="mb-1">
              <b-form-input
                v-model="rescheduleInterviewTime.addNote"
                class="w-sm-75"
                type="text"
                placeholder="Enter Note"
              />
            </b-form-group>
            <!-- <div class="d-flex justify-content-center">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="mx-auto d-inline-block my-3"
                          variant="primary"
                          @click="handleReschedule()"
                        >
                          Update
                        </b-button>
                      </div> -->
          </div>
        </b-col>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"

import axios from "@/libs/axios"
import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BFormTimepicker,
  BFormDatepicker,
  BForm,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import { required } from "@validations"

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    AppTimeline,
    AppTimelineItem,
    BDropdown,
    BDropdownItem,
    BBadge,
    BFormTimepicker,
    BFormDatepicker,
    BForm,
  },
  directives: {
    Ripple,
  },
  props: {
    assignments: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      showExperience: 3,
      required,
      showQualification: 3,
      graduationStartYear: null,
      graduationEndYear: null,
      value2: null,
      graduation: [],
      dir: "ltr",
      trueValue: true,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      isExpert: false,
      filter: null,
      filterTwo: "",
      filterOn: [],
      selectInstitute: [],
      selectedCompany: [],
      selectedCompanyType: null,
      selectedLocType: null,
      selectedIndustries: [],
      industries: [],
      selectedFunctions: [],
      functions: [],
      selectedSubFunctions: [],
      subFunctions: [],
      selectedSkills: [],
      location: "",
      searchName: "",
      skills: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        "show_details",
        { key: "candidate_id", label: "Candidate", sortable: true },
        "questions",
        "actions",
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      interviewInfo: {
        id: "",
        full_name: "",
        interviewTitle: "",
        selectDate: "",
        startTime: "",
        endTime: "",
        interviewLink: "",
        password: "",
        addNote: "",
        candidateNote: "",
      },
      rescheduleInterviewTime: {
        full_name: "",
        interviewTitle: "",
        selectDate: "",
        startTime: "",
        endTime: "",
        interviewLink: "",
        password: "",
        sender_note: "",
        addNote: "",
        candidateNote: "",
      },
      previewAssignmentData: {
        id: 0,
        client_id: 0,
        candidate_id: 0,
        assignment_id: 0,
        interview_id: 0,
        interview: {
          id: 0,
          client_id: 0,
          assignment_id: 0,
          status: "",
          start_date: "",
          start_time: "",
          sender_note: "d",
          candidate_note: "",
          end_time: "",
          link: "",
          password: "",
          title: "",
        },
        user: {
          name: "",
        },
      },
    }
  },
  computed: {},
  watch: {
    isExpert(value) {
      if (!value) {
        this.fields = [
          "show_details",
          { key: "candidate_id", label: "Candidate", sortable: true },
          "questions",
          "actions",
        ]
      } else {
        this.fields = [
          "show_details",
          { key: "candidate_id", label: "Candidate", sortable: true },
          "questions",
          "actions",
        ]
      }
    },
  },
  mounted() {
    this.getCandidates()
  },
  created() {},
  updated() {},

  methods: {
    interviewModal(candidate) {
      this.previewAssignmentData = candidate
      console.log(candidate)
      this.interviewInfo.id = candidate.interview.id
      this.interviewInfo.full_name = candidate.user.name
      this.interviewInfo.interviewTitle = candidate.interview.title
      this.interviewInfo.selectDate = candidate.interview.start_date
      this.interviewInfo.startTime = candidate.interview.start_time
      this.interviewInfo.endTime = candidate.interview.end_time
      this.interviewInfo.interviewLink = candidate.interview.link
      this.interviewInfo.password = candidate.interview.password
      this.interviewInfo.addNote = candidate.interview.sender_note
      this.interviewInfo.candidateNote = candidate.interview.candidate_note
      this.$refs["interview-modal"].show()
    },
    interviewEditModal() {
      this.$refs["interview-modal"].hide()
      this.$refs["interview-edit-modal"].show()
    },
    hideInterviewModal() {
      this.$refs["interview-modal"].hide()
    },
    showRescheduleModal(candidate) {
      this.previewAssignmentData = candidate
      console.log(candidate)
      this.rescheduleInterviewTime.full_name = candidate.user.name
      this.rescheduleInterviewTime.interviewTitle = candidate.interview.title
      this.rescheduleInterviewTime.selectDate = candidate.interview.start_date
      this.rescheduleInterviewTime.startTime = candidate.interview.start_time
      this.rescheduleInterviewTime.endTime = candidate.interview.end_time
      this.rescheduleInterviewTime.interviewLink = candidate.interview.link
      this.rescheduleInterviewTime.password = candidate.interview.password
      this.rescheduleInterviewTime.addNote = candidate.interview.sender_note

      // eslint-disable-next-line operator-linebreak
      this.rescheduleInterviewTime.candidate_note =
        candidate.interview.candidate_note

      this.$refs["reschedule-interview"].show()
    },
    hideRescheduleInterviewModal() {
      this.$refs["reschedule-interview"].hide()
    },

    rescheduleInterviewEditModal() {
      this.$refs["reschedule-interview"].hide()
      this.$refs["reschedule-interview-modal-edit"].show()
    },

    getSelected(option) {
      return (option && option.name) || ""
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async getCandidates() {
      axios
        .get(
          `/client/candidate_assignment/${this.$route.params.id}?filter[status]=Interview`
        )
        .then((res) => {
          this.items = res.data
          this.totalRows = this.items.length

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Candidate List`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully fetched Candidate List!`,
            },
          })
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
    handleStatusChange(status, data) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to Change Candidate Status.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            let sendingData = {}
            if (status === "Selected") {
              sendingData = {
                status,
                assignment_id: data.assignment_id,
                candidate_id: data.candidate_id,
                inner_status: "Confirmed",
              }
            } else {
              sendingData = {
                status,
                assignment_id: data.assignment_id,
                candidate_id: data.candidate_id,
                id: data.id,
              }
            }
            axios
              .post(`candidate_assignment/change_status`, sendingData)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Assignment Status`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Changed Assignment Status!`,
                  },
                })
                this.$emit("detailed-getAssignment")
              })
              .catch((error) => {
                console.log(error.response.status)
                errorResponse(error, this.$router)
              })
          }
        })
    },
    async handleInterviewEdit() {
      const interviewData = {
        interviewId: this.interviewInfo.id,
        interviewTitle: this.interviewInfo.interviewTitle,
        updateDate: this.interviewInfo.selectDate,
        updateStartTime: this.interviewInfo.startTime,
        updateEndTime: this.interviewInfo.endTime,
        interviewLink: this.interviewInfo.interviewLink,
        password: this.interviewInfo.password,
        addNote: this.interviewInfo.addNote,
      }
      axios
        .post("/edit/interview_candidate", interviewData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Assignment Interview`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully Edited the candidate Interviews!`,
            },
          })
          this.$refs["interview-modal"].hide()
          this.getCandidates()
        })
        .catch((error) => {
          console.log(error)
          this.$refs["interview-modal"].hide()
          errorResponse(error, this.$router)
        })
    },
    async rescheduleInterview() {
      const interviewData = {
        interviewId: this.previewAssignmentData.interview_id,
        interviewTitle: this.rescheduleInterviewTime.interviewTitle,
        updateDate: this.rescheduleInterviewTime.selectDate,
        updateStartTime: this.rescheduleInterviewTime.startTime,
        updateEndTime: this.rescheduleInterviewTime.endTime,
        interviewLink: this.rescheduleInterviewTime.interviewLink,
        password: this.rescheduleInterviewTime.password,
        addNote: this.rescheduleInterviewTime.addNote,
        assignmentId: this.previewAssignmentData.assignment_id,
        candidateId: this.previewAssignmentData.candidate_id,
      }
      axios
        .post("/interview_candidate", interviewData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Assignment Interview`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully Rescheduled the candidate Interviews!`,
            },
          })
          this.$refs["interview-modal"].hide()
          this.getCandidates()
        })
        .catch((error) => {
          console.log(error)
          this.$refs["interview-modal"].hide()
          errorResponse(error, this.$router)
        })
    },
    async handleAcceptInterview() {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to Confirm the Interview Request!.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            axios
              .post(`/assignments/candidate_accept_interview`, {
                assignment_id: this.previewAssignmentData.assignment_id,
                candidate_id: this.previewAssignmentData.candidate_id,
              })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Interview Confirm`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Scheduled the Interview Invite!`,
                  },
                })
                this.$refs["reschedule-interview"].hide()
                this.getCandidates()
              })
              .catch((error) => {
                this.$refs["reschedule-interview"].hide()
                console.log(error)
                errorResponse(error, this.$router)
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.bor-1 {
  border-right: 1px solid lightgray;
}

.in__line {
  display: flex;
  flex-direction: row;
}

.y-center {
  display: flex;
  flex-direction: column;

  justify-content: center;
}

.title__box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.card__box {
  display: flex;
  justify-content: space-between;
}

.card__left {
  flex: 1;
}
.loc {
  font-size: 11px;
  padding-right: 5px;
}

.font-11 {
  font-size: 11px;
}

.bold {
  font-family: bold;
}
.inline {
  display: inline !important;
}

.name {
  margin-bottom: 0px;
}
.timeline-item {
  padding-bottom: 5px !important;
}

// .card-body {
//   padding: 0px !important;
// }
.experience_btn {
  cursor: pointer;
}
.location {
  display: inline !important;
}
.icon {
  margin-top: 5px;
}
.executive {
  margin-right: 5px;
  font-size: 12px;
}

.row_margin {
  margin-left: 0px;
  margin-right: 0px;
}
</style>

Row select support Selection mode: multi ID AVAT
